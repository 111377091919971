//
//
//
//
//
//
//
//
//
//

let loaded = false;
export default {
  name: 'CookieIframe',
  data(){
    return{
      cookies: this.$cookies,
      loaded: false,
    }
  },

  computed: {
    iframeEnabled(){
      return this.cookies.enabled.filter(c =>{return c.name === 'functional'}).length > 0
    },

    iframeText(){
      return this.cookies && this.cookies.text ? this.cookies.text.blockedIframe : '';
    }
  }
}
