const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
const parseUrl = require('url-parse');


export default {

	beforeMount()
	{
		this.$root.$on('scroll-to-anchor', (event) => this.scrollToAnchor(event));
	},

	beforeDestroy()
	{
		this.$root.$off('scroll-to-anchor');
	},


	methods:
	{
		scrollToAnchor(event)
		{

			consola.debug('scrollToAnchor: ', event);

			let href = '#';
			if (typeof event == 'string')
			{
				href = event;
			}
			else
			{
				href = event.target.getAttribute('href') || '';
			}

			if ( href.indexOf('#') == -1 )
			{
				return;
			}

			let currentPath = (window.location.pathname);
			let hrefUrl = parseUrl(href);

			if (currentPath != hrefUrl.pathname)
			{
				return;
			}
			if (typeof event != 'string')
			{
				event.preventDefault();
				event.stopPropagation();
			}
			let parts = href.split('#');
			if (parts.length > 1)
			{
				let hash = parts[1];
				const target = document.querySelector(`[data-section="${hash}"]`) || document.querySelector(`#${hash}`);
				let offset = 0;
				let menuEl = document.querySelector('.Menu');
				if (menuEl)
				{
					let menuBox = menuEl.getBoundingClientRect();
					offset = menuBox.bottom;
					consola.debug('scroll extra offset: ', offset)
				}

				if (target)
				{
					if (this.smoothScrollbar)
					{
						this.smoothScrollbar.scrollIntoView(target, {
							offsetTop: offset
						});
					}
					else
					{
						// debugger;
						let box = target.getBoundingClientRect();
						const scrollY = (window.scrollY || document.body.scrollTop) - offset;
						if (window.innerWidth > 768)
							this.$scrollTo(box.top + scrollY);
						else
						{
							setTimeout( () => this.$scrollTo(box.top + scrollY), 100 );
						}
						consola.debug('scrollTo: ', box.top)
					}
				}
				// else
				// {
				// 	debugger
				// }
				// TODO: calc bounding box
			}

		},
	}
}
