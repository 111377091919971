
// console.log('on-nuxt-ready read...')
if (process.browser)
{
	let readyCallbacks = [];
	let timer = null;
	window.onNuxtReady = function(cb)
	{
		if (window.onHumbleReady)
		{
			if (timer)
			{
				clearInterval(timer);
				timer = null;
			}

			// console.log('onNuxtReady...')
			// debugger;
			window.onNuxtReady = window.onHumbleReady;
			for (let i = 0; i < readyCallbacks.length; i++)
			{
				window.onNuxtReady( readyCallbacks[i] );
			}
			window.onNuxtReady(cb);
			readyCallbacks = [];
		}
		else
		{
			readyCallbacks.push(cb);
			if (!timer)
			{
				this.setInterval( function()
				{
					if (window.onHumbleReady)
					{
						if (timer)
						{
							clearInterval(timer);
							timer = null;
						}
						// console.log('onNuxtReady...')
						window.onNuxtReady = window.onHumbleReady
						for (let i = 0; i < readyCallbacks.length; i++)
						{
							window.onNuxtReady( readyCallbacks[i] );
						}
						readyCallbacks = [];
					}
				}, 50 );
			}
		}
	};
}

export default function renameOnInitFunction(moduleOptions)
{
	console.log('on-nuxt-ready init...')

	if (process.browser)
	{
		window.onNuxtReady = window.onHumbleReady;
	}
}
