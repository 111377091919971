import { render, staticRenderFns } from "./ItemAddForm.vue?vue&type=template&id=653fae04&scoped=true&"
import script from "./ItemAddForm.vue?vue&type=script&lang=js&"
export * from "./ItemAddForm.vue?vue&type=script&lang=js&"
import style0 from "./ItemAddForm.vue?vue&type=style&index=0&id=653fae04&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653fae04",
  null
  
)

export default component.exports