export default {

	data()
	{
		return {
			// _scrollSpyElems: [],
			_scrollSpyObserver: null,
		}
	},

	mounted()
	{

	},

	beforeDestroy()
	{
		if (this._scrollSpyObserver)
			this._scrollSpyObserver.disconnect()

		this._scrollSpyObserver = null;
		// this._scrollSpyElems = [];

	},

	methods:
	{
		scrollSpyInit(observerConfig, selector, hashFromElemFn)
		{
			selector = selector || '[data-section]';
			hashFromElemFn = hashFromElemFn || ( el => el.getAttribute('data-section') );
			observerConfig = observerConfig || {};

			let config = {
					root: null,
					rootMargin: '0px',
					threshold: 0.5, //1.0,
					// threshold: [0.4, 0.52], //1.0,
					...observerConfig,
				};

			const threshold = Array.isArray(config.threshold) ? config.threshold[0] : config.threshold;
			const anchors = Array.from(document.querySelectorAll('.Menu a')).filter(x => !!x.hash );

			function observerFn(entries, observer)
			{
				// console.log('observerFn: ', entries);

				entries.forEach(entry =>
					{
						const hash = hashFromElemFn(entry.target);
						if (!hash)	return;

						if (entry.intersectionRatio > threshold)
						{
							anchors.forEach(x => {
								if ( x.hash == ('#'+hash) )
									x.classList.add('target-in-view');
							})
						}
						else
						{
							anchors.forEach(x => {
								if ( x.hash == ('#'+hash) )
									x.classList.remove('target-in-view');
							})
						}
					})

			}

			this._scrollSpyObserver = new IntersectionObserver(observerFn, config);

			let elems = Array.from(document.querySelectorAll(selector));

			elems.forEach( x => this._scrollSpyObserver.observe(x) );
		}
	}

}
