export default {

	data()
	{
		return {
			showExtForm: false,
			formUrl: '',
		}
	},

	mounted()
	{
		// debugger;
		this.$root.$on( 'show-external-form', (params) =>
		{
			// debugger;
			// this.formLocaleUrls = params || {};
			this.formUrl = params || '';
			this.showExtForm = true;
		});
	},

	beforeDestroy()
	{
		this.$root.$off( 'show-external-form' )
	},

}
