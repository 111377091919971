const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SmoothScrollbar from '~/lib/smooth-scrollbar-gsap/index.js';

export default {

	data()
	{
		return {
			_prevScrollY: -100,
			scrollTop: true,
			scrollCallback: null,
			smoothScrollbar: null,
			rafCallback: null,
		};
	},

	computed:
	{
		...mapGetters(['getString', 'commonWords', 'topBanner', 'lang', 'langShort', 'allLanguages', 'windowWidth', 'isMobile', 'isTablet', 'isDesktop', 'isEditingPage']),
	},

	watch:
	{
		isEditingPage(newVal)
		{
			if (newVal)
			{
				this.destroySmoothScroll();
			}
			else
			{
				this.initSmoothScroll();
			}
		}
	},

	beforeDestroy()
	{
		if ( this.scrollCallback )
		{
			// window.removeEventListener('scroll', this.scrollCallback);
			document.body.removeEventListener('scroll', this.scrollCallback);
			window.removeEventListener('scroll', this.scrollCallback);
			this.scrollCallback = null;
		}

		this.$root.$off('setScrolledToTop');
		this.$root.$off('scrollBy');
		// this.$root.$off('showMobileMenu');
		// this.$root.$off('showModal');
		// this.$root.$off('showTopBanner');
	},

	mounted()
	{
		this.$registerScrollTopListener();
		this.$root.$on('setScrolledToTop', (isScrollTop) => { this.scrollTop = isScrollTop; });
		this.$root.$on('scrollBy', (offset) => { this.scrollBy(offset); });
		this.$root.$on('scrollToTop', () => { this.scrollToTop(); });

		const self = this;
		function onScrollEvent(event)
		{
			const y = document.body.scrollTop || window.scrollY;
			// consola.debug('onScroll: ', y);
			if (self._prevScrollY != y)
			{
				self.setScrollTop(y);
				self._prevScrollY = y;
			}
		}

		// debugger;
		// if (this.$Scrollbar)
		if ( process.client && typeof window !== 'undefined' && !this.isEditingPage )
		{
			if ( window.innerWidth > 768 )
				this.$nextTick( ()=>
				{
					// consola.debug('Scrollbar init')
					this.initSmoothScroll();

					if ( window.location.hash )
					{
						consola.debug('url has hash : ', window.location.hash);
						// debugger;
						if (this.scrollToAnchor)
						{
							this.scrollToAnchor(window.location.pathname + window.location.hash);
						}
					}
				})
			else
			{
				consola.debug('adding "scroll" event handler')
				this.scrollCallback = onScrollEvent;
				// this.$raf.addListener()
				// window.addEventListener('scroll', this.scrollCallback)
				document.body.addEventListener('scroll', this.scrollCallback)
				window.addEventListener('scroll', this.scrollCallback)
				// this.setScrollTop( this.smoothScrollbar.offset['y'] )
			}
		}
		if ( process.client && typeof window !== 'undefined' && this.isEditingPage )
		{
			consola.debug('adding "scroll" event handler')
			this.scrollCallback = onScrollEvent;
			// this.$raf.addListener()
			// window.addEventListener('scroll', this.scrollCallback)
			window.addEventListener('scroll', this.scrollCallback)
		}
	},

	methods:
	{
		...mapMutations(['setScrollTop', ]),

		initSmoothScroll()
		{
			consola.debug('initSmoothScroll')
			// if ( window.location.pathname.split('/').pop() == 'edit' )
			// {
			// 	// disable the scrollbar on the admin UI
			// 	// because it locks the spacebar
			// 	return;
			// }
			let pageContent = document.querySelector('#page-content');
			if (!pageContent)	return;
			// debugger;
			// return;
			this.smoothScrollbar = SmoothScrollbar.init(document.querySelector('#page-content'), {
													renderByPixels: false,
													// skewScale: window._disableSkew ? 0 : 0.01 * 0.5,
													// skewScale: 0.01 * 0.5,
													rafLoop: this.$raf,
													tracksParent: document.body,
													alwaysShowTracks: true,
												});
			document.documentElement.classList.add('smooth-scroll');
			consola.debug('scrollbar', this.smoothScrollbar);
			this.rafCallback = ()=>
								{
									this.setScrollTop( this.smoothScrollbar.offset['y'] )
									// consola.debug('y: ', )
								};
			this.$raf.addListener('tick', this.rafCallback);
		},

		destroySmoothScroll()
		{
			consola.debug('destroySmoothScroll')
			if (this.smoothScrollbar)
			{
				let scrollbars = document.querySelectorAll('body > .scrollbar-track');
				if (scrollbars)
				{
					for (let i = 0; i < scrollbars.length; i++)
					{
						document.body.removeChild(scrollbars[i]);
					}
				}
				this.smoothScrollbar.destroy();
			}
			this.smoothScrollbar = null;
			if (this.rafCallback)
			{
				this.$raf.removeListener('tick', this.rafCallback)
				this.rafCallback = null;
			}
			document.documentElement.classList.remove('smooth-scroll');
		},

		scrollBy(offset)
		{
			// consola.debug('scrollBy: ', offset)

			if (this.smoothScrollbar)
			{
				// this.smoothScrollbar.scrollTo(this.smoothScrollbar.scrollLeft,
				// 							this.smoothScrollbar.scrollTop + offset,
				// 							300)
				this.smoothScrollbar.addMomentum(0, offset);
			}
			else
			{
				// debugger;
				const scrollY = (window.scrollY || document.body.scrollTop) - offset;
				if (window.innerWidth > 768)
					this.$scrollTo(scrollY + offset);
				else
				{
					setTimeout( () => this.$scrollTo(scrollY + offset), 100 );
				}
				// consola.debug('scrollTo: ', box.top)
			}
		},

		scrollToTop()
		{
			if (this.smoothScrollbar)
			{
				this.smoothScrollbar.scrollTo(this.smoothScrollbar.scrollLeft,
											0,
											500)
				// this.smoothScrollbar.addMomentum(0, offset);
			}
			else
			{
				// debugger;
				// const scrollY = (window.scrollY || document.body.scrollTop) - offset;
				if (window.innerWidth > 768)
					this.$scrollTo(0);
				else
				{
					setTimeout( () => this.$scrollTo(0), 100 );
				}
				// consola.debug('scrollTo: ', box.top)
			}
		}


	}


}
