
export default {

	data()
	{
		return {
			modalUrl: null,
		}
	},

	beforeMount()
	{
		this.$root.$on('open-modal', el =>
		{
			if (!el)	return;
			if (el.tagName)	// check if it is a DOMElement
			{
				let href = el.getAttribute('href') || '';
				if (!href)	return;
				if (this.$store.getters.isEditingPage)
				{
					this.$router.push(href);
					return;
				}
				if (href.indexOf('http') > -1)
				{
					href = href.split(window.location.host)[1];
				}

				if (href.charAt(0) != '/' && href.charAt(0) != '.')
					href = '/' + href;
				// href = href.replace('https://', '').replace('http://', '');
				this.modalUrl = href;
			}
			else if (el.block_id || el.instructor_id)
			{
				// else it is a block
				this.modalUrl = el;
			}

		})
	},

	beforeDestroy()
	{
		this.$root.$off('open-modal');
	}
}
