export default function ({ app, store, $auth, route, req, redirect,  })
{
	console.log('admin-editable path: ', route.path.split('/'))
	if (route.path)
	{
		const host = req ? req.headers.host : window.location.host.split(':')[0];
		const isAcademy = host.indexOf('academy') == 0;

		let parts = route.path.split('/').filter(x => !!x);
		// if (parts[0] == '')	parts.shift();
		let last = parts.length ? parts[parts.length - 1] : '';
		let isNotLocale = isAcademy || parts.length > 1;
		if (isNotLocale && last=='edit')
		{
			if ( !$auth.loggedIn )
			{
				console.log('admin-editable: edit page of guest user ')
				return redirect( '/login?redirect=' + encodeURIComponent( route.fullPath ) )
			}
			else
			{
				console.log('admin-editable: edit page of logged in user ', JSON.stringify($auth.user) )
			}
		}

		if ( $auth.loggedIn )
		{
			// if (parts.length > 1 && last!='edit')
			// {
			// 	let query = '';
			// 	if (route.query && Object.keys(route.query).length > 0 )
			// 	{
			// 		for (let prop of route.query)
			// 		{
			// 			if (query)	query += '&';
			// 			query += `${prop}=${route.query[prop]}`
			// 		}
			// 		query = '?'+query;
			// 	}
			// 	console.log('admin-editable: regirecting to /edit page of logged in user ', JSON.stringify($auth.user) )
			// 	return redirect( route.path + '/edit' + route.hash + query);
			// }
		}
	}

}
