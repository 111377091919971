//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cssVars from 'css-vars-ponyfill';
export default {
  name: 'CookieControl',
  props: {
    locale: {
      default: 'en'
    }
  },
  data(){
    return{
      saved: true,
      colorsSet: false,
      cookies: this.$cookies,
    }
  },

  computed: {
    expirationDate(){
      let date = new Date();
      date.setFullYear(date.getFullYear()+1);
      return date.toUTCString();
    },

    optionalCookies(){
      return this.cookies.optional;
    }
  },

  mounted()
  {
    this.$store.commit('setGdprConsent', this.cookies.consent);
  },

  methods: {
    toogleCookie(cookie){
      let cookieName = this.cookies.slugify(this.getCookieFirstName(cookie));
      if(this.saved) this.saved = false;
      if(!this.cookies.enabledList.includes(cookieName)) this.cookies.enabledList.push(cookieName);
      else this.cookies.enabledList.splice(this.cookies.enabledList.indexOf(cookieName), 1);
    },

    setConsent({type, consent=true}){
      this.cookies.set({name: 'cookie_control_consent', value: consent, expires: this.expirationDate});
      let enabledCookies = type === 'partial' && consent ? this.cookies.enabledList : [...this.optionalCookies.map(c =>{
        return this.cookies.slugify(this.getCookieFirstName(c.name))
      })];
      this.cookies.set({name: 'cookie_control_enabled_cookies', value: consent ? enabledCookies.join(',') : '', expires: this.expirationDate});
      this.cookies.consent = consent;
      this.$store.commit('setGdprConsent', consent);
      // if(process.browser) window.location.reload(true);
    },

    getDescription(description){
      if(typeof(description) === 'string') return ` ${this.cookies.dashInDescription !== false ? '-' : ''} ${description}`;
      else if(description[this.locale]) return ` ${this.cookies.dashInDescription !== false ? '-' : ''} ${description[this.locale]}`;
      return '';
    },

    getName(name){
      return name === 'functional' ? this.cookies.text['functional'] : typeof(name) === 'string' ? name : name[this.locale] ? name[this.locale] : name[Object.keys(name)[0]];
    },

    getCookieFirstName(name){
      return typeof(name) === 'string' ? name : name[Object.keys(name)[0]]
    },

    async setTexts(isChanged=false){
      let text = null;
      try {
        const module = require(`../locale/${this.locale}`);
        text = module.default;
      } catch (e) {
        console.error(`There are no texts for your locale: ${this.locale}`)
      }
      if(this.cookies.text && Object.keys(this.cookies.text).length > 0){
        if(this.cookies.text.locale){
          Object.assign(text, this.cookies.text.locale[this.locale])
        }
        if(!isChanged) Object.assign(text, this.cookies.text)
      }
      this.$set(this.$cookies, 'text', text);
    }
  },

  async beforeMount (){
    await this.setTexts();
    if(process.browser && this.cookies.colors){
      let key = null;
      let variables = {};
      for(key in this.cookies.colors){
        let k = key.toLowerCase().includes('unactive') ? key.replace(/Unactive/g, 'Inactive') : key;
        variables[`cookie-control-${k}`] = `${this.cookies.colors[key]}`
      }
      cssVars({variables})
    }
    if(this.cookies.get('cookie_control_consent').length === 0){
      this.optionalCookies.forEach(c =>{
        if(c.initialState === true) {
          this.cookies.enabledList.push(this.cookies.slugify(this.getCookieFirstName(c.name)));
        }
      })
    }
    this.colorsSet = true;
  },

  watch: {
    async locale(){
      await this.setTexts(true);
    }
  }
}
